import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { UserOutlined, FileTextOutlined } from "@ant-design/icons"
import { GETTOKEN } from "../../utils/buizCont";
const Headers = () => {
  const nav = useNavigate();
  const goLogin = () => {
    //判断是否登录  
    //1.未登录 去登录
    //2.已登录 去个人主页
    if (GETTOKEN()) {
      nav("/students")
    } else {
      nav("/register")
    }

  }
  return (
    <div className="headers">
      <img src="../assets/header.png" alt="" className="header-pc" />
      <img src="../assets/header-wap.png" alt="" className="header-wap" />
      <div className="header-content">
        <div className="sel-div">

        </div>
        <div
          className="modular"
        >
          {" "}
          <UserOutlined />
          <span className="login-span" onClick={() => { goLogin() }}>登录/注册 </span> <FileTextOutlined /> <span onClick={() => {
            window.open("https://www.wjx.cn/vm/wPikBcf.aspx");
          }}> 问卷调查</span>
        </div>
      </div>
    </div>
  );
};
export default Headers;
