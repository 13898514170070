import TeacherHomepage from "../pages/TeacherHomepage";
import TeacherInfo from "../pages/TeacherInfo";
import UserInfo from "../pages/UserInfo";
import Login from "../pages/Login";
import ClassRecord from "../pages/ClassRecord";
import Time from "../components/Time";
import Questionnaire from "../pages/Questionnaire";
const routes = [
  {
    exact: true,
    path: "/index.html",
    element: <Login />,
  },
  {
    exact: true,
    path: "/",
    element: <Login />,
  },
  {
    exact: true,
    path: "/teacherlist",
    element: <TeacherHomepage />,
  },
  {
    exact: true,
    path: "/teachers",
    element: <TeacherInfo />,
  },
  {
    exact: true,
    path: "/students",
    element: <UserInfo />,
  },
  {
    exact: true,
    path: "/register",
    element: <Login />,
  },
  {
    exact: true,
    path: "/class-record",
    element: <ClassRecord />,
  },
  {
    exact: true,
    path: "/time",
    element: <Time />,
  },
  {
    exact: true,
    path: "/questionnaire",
    element: <Questionnaire />
  }
];
export default routes;
