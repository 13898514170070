import Header from "../../components/Header";
import TeacherCom from "../../components/TeacherCom";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import CalendarFun from '../UserInfo/calendar';
import GlobalContext from "../../context"
import { theacherList, getNotice } from '../../api'
import { useContext, useState, useEffect } from "react";
import React from "react";
import "./style.scss";
import { UserOutlined, FileTextOutlined } from "@ant-design/icons"
import "./style.scss"
import "../../components/Header/style.scss"
import { GETTOKEN } from "../../utils/buizCont";
const TeacherHomepage = () => {
    const nav = useNavigate();
    const [teacherDataList, setTeacherDataList] = useState();
    const [laText, setLaText] = useState("根据语言搜索");
    useEffect(() => {
        theacherData();
    }, []);
    const goLogin = () => {
        //判断是否登录  
        //1.未登录 去登录
        //2.已登录 去个人主页
        if (GETTOKEN()) {
            nav("/students")
        } else {
            nav("/register")
        }

    }
    //老师列表
    const theacherData = async (keyword = "") => {
        const res = await theacherList(1, 100, keyword)
        setTeacherDataList(res.data.list)
    }

    return (
        <div className="teacher-homepage">
            <div className="header">
                <img src="../assets/header.png" alt="" className="header-pc" />
                <img src="../assets/header-wap.png" alt="" className="header-wap" />
                <div className="header-content">
                    <div className="sel-div">
                        <select name="" id="sel" className="select-lag" onChange={(value) => {
                            console.log(document.querySelector("#sel").value);
                            theacherData(document.querySelector("#sel").value);
                            setLaText(document.querySelector("#sel").value || "全部");
                        }}>
                            <option value="">全部</option>
                            <option value="汉语">汉语</option>
                            <option value="英语">英语</option>
                            <option value="日语">日语</option>
                            <option value="匈牙利语">匈牙利语</option>
                            <option value="意大利语">意大利语</option>
                            <option value="捷克语">捷克语</option>
                        </select>
                        {laText}
                    </div>
                    <div className="modular">
                        {" "}
                        <UserOutlined />
                        <span className="login-span" onClick={() => { goLogin() }}>登录/注册 </span> <FileTextOutlined /> <span onClick={() => {
                            window.open("https://www.wjx.cn/vm/wPikBcf.aspx");
                        }}> 问卷调查</span>
                    </div>
                </div>
            </div >
            {teacherDataList ? <div className="teacherContent">
                {
                    teacherDataList?.map((item, index) => {
                        return <div className="teacher-item" key={index}><TeacherCom item={item}></TeacherCom></div>
                    })
                }
            </div> :
                <div className="teacherContent">
                    {
                        teacherDataList?.map((item, index) => {
                            return <div className="teacher-item" key={index}><TeacherCom item={item}></TeacherCom></div>
                        })
                    }
                </div>
            }

            <Footer />
        </div >
    );
}
export default TeacherHomepage;


