
const columnsTime = (startTime, endTime) => {
    console.log("+______________________________", startTime);
    if (!startTime && !endTime) { return '' }
    const startDate = new Date(startTime);
    const startHours = startDate.getHours()
    const startMinutes = startDate.getMinutes();
    const endDate = new Date(endTime);
    const endHours = endDate.getHours()
    const endMinutes = endDate.getMinutes();
    return `${startHours}:${startMinutes < 10 ? '0' + startMinutes : startMinutes} - ${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`
}
let timeArr = [];
const sendEndF = (event, data) => {
    console.log("=======", event, JSON.stringify(data));

    if (timeArr.indexOf(data.ID) == -1) {
        timeArr.push(data.ID)
        console.log("不存在")
        console.log("push", timeArr);
        event.target.style.color = "red"
    } else {
        timeArr.forEach((item, index) => {
            timeArr.splice(index, 1)
        });
        console.log(timeArr);
        console.log("存在,索引是：")
        event.target.style.color = "#3e3e3e"
    }
    console.log("=======", timeArr);
    // localStorage.setItem("data", JSON.stringify(data))

    localStorage.setItem("data", JSON.stringify(timeArr))

}

const renderTime = (data) => {
    if (!data) return <div className="no-time">No idle</div>
    return (
        <div className="time-div" onClick={(event, item) => { sendEndF(event, data); console.log(item); }}>
            {columnsTime(data?.start_timestamp, data?.end_timestamp)}
        </div>
    );
}
export default [
    {
        title: "周一",
        dataIndex: "weekday_1",
        key: "weekday_1",
        width: '14%',
        render(data) { return renderTime(data); }
    },
    {
        title: "周二",
        dataIndex: "weekday_2",
        key: "weekday_2",
        width: '14%',
        render(data) { return renderTime(data); }
    },
    {
        title: "周三",
        width: '14%',
        dataIndex: "weekday_3",
        key: "weekday_3",
        render(data) { return renderTime(data); }
    },
    {
        title: "周四",
        width: '14%',
        dataIndex: "weekday_4",
        key: "weekday_4",
        render(data) { return renderTime(data); }
    },
    {
        title: "周五",
        width: '14%',
        dataIndex: "weekday_5",
        key: "weekday_5",
        render(data) { return renderTime(data); }
    },
    {
        title: "周六",
        width: '14%',
        ID: 5,
        dataIndex: "weekday_6",
        key: "weekday_6",
        render(data) { return renderTime(data); }
    },
    {
        title: "周日",
        width: '14%',
        dataIndex: "weekday_7",
        key: "weekday_7",
        render(data) { return renderTime(data); }
    },
];