
export default
    {
        "code": 200, //code码：200=成功
        "msg": "请求成功", //消息提示
        "data": [
            {
                "ID": 3, //用户id
                "students_id": 3,
                "teacher_id": 1, //老师ID
                "subject": "历史", //科目
                "weekday": 3, //星期：1=周一，7=周日
                "start_time": 1669207458837, //开始时间
                "end_time": 1669209458837, //结束时间
                "updated_at": "2022-11-19T00:32:46.301+08:00" //更新时间
            },
            {
                "ID": 3, //用户id
                "students_id": 3,
                "teacher_id": 1, //老师ID
                "subject": "历史", //科目
                "weekday": 3, //星期：1=周一，7=周日
                "start_time": 1669907458837, //开始时间
                "end_time": 1669209458837, //结束时间
                "updated_at": "2022-11-19T00:32:46.301+08:00" //更新时间
            },
            {
                "ID": 3, //用户id
                "students_id": 3,
                "teacher_id": 1, //老师ID
                "subject": "道得", //科目
                "weekday": 3, //星期：1=周一，7=周日
                "start_time": 1669209458837, //开始时间
                "end_time": 1669209458837, //结束时间
                "updated_at": "2022-11-19T00:32:46.301+08:00" //更新时间
            },
            {
                "ID": 3, //用户id
                "students_id": 3,
                "teacher_id": 1, //老师ID
                "subject": "武术", //科目
                "weekday": 3, //星期：1=周一，7=周日
                "start_time": 1669219458837, //开始时间
                "end_time": 1669209458837, //结束时间
                "updated_at": "2022-11-19T00:32:46.301+08:00" //更新时间
            }
        ] //数据体
    }
