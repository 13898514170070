import React, { useContext } from "react";
import "./style.scss"
import { useNavigate } from "react-router-dom"
import GlobalContext from "../../context"
const TeacherCom = ({ item }) => {
    const nav = useNavigate()
    const { state, dispatch } = useContext(GlobalContext);
    console.log("item", item);
    return (
        <div className="teacher-com" onClick={() => {
            //跳转详情
            nav(`/teachers`, { state: { id: item.ID } });
            // navigate("/order", { state: { orderInfo: item } });
        }}>
            <div className="head-portrait">
                <img src={item?.header_img} alt="" />
            </div>
            <div className="name-text">姓名: {item?.nick_name}</div>
            <div className="language-text">语言技能：{item?.language}</div>
            <div className="label">
                {
                    item.areas?.map((item1, index) => {
                        return (
                            <span className="time-label" key={index}>{item1}</span>
                        )
                    })}

            </div>
        </div>
    );
}
export default TeacherCom;