import { Badge, Calendar, message, TimePicker, DatePicker, Space, Modal } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { SmileTwoTone } from "@ant-design/icons"
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import dayLocaleData from 'dayjs/plugin/localeData';
import "./calendar.scss"
import { changeTime, leave } from "../../api"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Time from "../../components/Time"
import GlobalContext from "../../context"
dayjs.extend(dayLocaleData);

const NewCalendar = (props) => {
    const { configData = {}, studentTimeTable } = props;
    const [classData, setClassData] = useState([])
    const [vacateText, setVacateText] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const { RangePicker } = DatePicker;
    const nav = useNavigate();
    const { state, dispatch } = useContext(GlobalContext);
    useEffect(() => {
        console.log(configData);
        console.log("studentTimeTable_______________", studentTimeTable);
        console.log(props);
    }, [])
    const getListData = (value) => {

        let listData = [];
        studentTimeTable?.data.forEach((item, index) => {
            const date = new Date(item.start_timestamp);
            const day = date.getDate();
            const month = date.getMonth();
            const hours = date.getHours()
            const minutes = date.getMinutes()
            if (value.date() === day && month == value.month()) {
                listData.push({
                    type: 'success',
                    content: `${item.subject} (${hours}:${minutes})`,
                    key: index
                })
            }
        })
        return listData || [];

    };

    const dateCellRender = (value) => {
        // console.log('value', value);
        const listData = getListData(value);
        if (listData && listData.length > 0) {
            return (
                <div className="events">
                </div >
            );
        }
        return null

    };

    const onPanelChange = (value, mode) => {
        console.log('0000000000000', value.format('YYYY-MM-DD'), mode);
    };
    const time = (time) => {
        const date = new Date(time);
        const day = date.getDate();
        const month = date.getMonth();
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const times = hours + ":" + minutes;
        return times;
    }
    //修改时间
    // "course_plan_id": "1",

    //     "teacher_id": "1",

    //         "subject": "历史",

    //             "weekday": "7",

    //                 "start_time": "1668996000",

    //                     "end_time": "1668916800"
    //学生改时间
    const changeTimeFun = async (item) => {
        //时间转成时间戳
        console.log(item);
        if (endTime && startTime) {
            const data = {
                // course_plan_id: item.ID,
                // teacher_id: item.teacher_id,
                // subject: item.subject,
                // weekday: item.weekday,
                // start_time: startTime,
                // end_time: endTime,
                course_plan_id: 5, //学生原课表ID
                weekday: item.weekday,//老师课程计划ID
                start_time: time(item.start_timestamp),
                end_time: time(item.end_timestamp),
            }
            const res = await changeTime(data);
            if (res.code === 200) {
                message.success("申请发送成功，等待班主任审批")
            }

            console.log(res);
        } else {
            message.error("为输入修改时间");
        }

    }

    //学生请假
    const vacate = async (item) => {
        console.log(item)
        if (vacateText) {
            const data = {
                course_id: item.ID,
                content: vacateText
            }
            const res = await leave(data);
            if (res.code === 200) {
                message.success("已提交等待班主任批准")
            } else {
                message.error("超过了请假时间哦，请联系班主任")
            }
        } else {
            message.error("未说明请假原因");
        }


    }
    dayjs.extend(customParseFormat);

    const onChanges = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);//['2022-11-24 04:00', '2022-11-24 05:00']
        var date = new Date(dateString[0]);
        setStartTime(date.getTime());
        var date1 = new Date(dateString[1]);
        setEndTime(date1.getTime())
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    return (
        <div className="site-calendar-demo-card">
            <Calendar fullscreen={false} onPanelChange={onPanelChange} onChange={(value) => {

                let listData = [];
                studentTimeTable?.data.forEach((item, index) => {
                    const date = new Date(item.start_timestamp);
                    const day = date.getDate();
                    const month = date.getMonth();
                    const hours = date.getHours()
                    const minutes = date.getMinutes()
                    if (value.date() === day && month == value.month()) {
                        listData.push({
                            ...item
                        })
                    }
                })

                console.log('---------------', listData)
                setClassData(listData)
            }} dateCellRender={dateCellRender} />
            <div className='modify'>
                {
                    classData?.map((item, index) => {
                        return (
                            <div className='time-content' key={index}>
                                <div style={{ width: "50%" }}>
                                    <p>上课时间 : {time(item?.start_timestamp)}-{time(item?.end_timestamp)}</p>
                                    <div className='change-div'>
                                        <Space direction="vertical" size={8}>
                                            <RangePicker
                                                showTime={{
                                                    format: 'HH:mm',
                                                }}
                                                format="YYYY-MM-DD HH:mm"
                                                onChange={onChanges}
                                                onOk={onOk}

                                            />
                                        </Space>
                                        {/* <input className='' placeholder='开始时间' onChange={(e) => {
                                            setStartTime(e.target.value);
                                        }}></input>
                                        <input placeholder='结束时间' onChange={(e) => {
                                            setEndTime(e.target.value);
                                        }}></input> */}
                                    </div>

                                    <div className='btn'>
                                        <button onClick={(time) => {
                                            changeTimeFun(item)
                                            // const id = item.teacher_id;
                                            // console.log(item, id);
                                            // nav(`/time`, { state: { id: id } });
                                        }}>修改时间</button>

                                    </div>
                                </div>
                                <div>
                                    <p>请假</p>
                                    <div className='change-div'>

                                        <input className='' placeholder='请输入请假原因' onChange={(e) => {
                                            setVacateText(e.target.value)
                                        }}></input>
                                    </div>

                                    <div className='btn'>
                                        <button onClick={() => {
                                            vacate(item)
                                        }}>提交</button>

                                    </div>
                                </div>

                            </div>

                        )
                    })
                }

            </div>
        </div>
    );
}
export default NewCalendar;