import React, { useState } from "react";
import Header from "../../components/Header";
import TeacherCom from "../../components/TeacherCom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import "./style.scss";
import { useEffect } from "react";
import GlobalContext from "../../context";
import { useContext } from "react";
import configData from "./config";
import tableColumns from "./columns";
import { Space, Table, message } from "antd";
import { Appointment, theacherClass, changeTime } from "../../api"
import "./style.scss"
const Time = ({ setIsModalOpen, teacherID }) => {
    const { state, dispatch } = useContext(GlobalContext);
    const nav = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [columns, setColumns] = useState(tableColumns || []);
    const [tableData, setTableData] = useState([]);
    const [course_plan_id, setCourse_plan_id] = useState();
    useEffect(() => {
        getThaecherClass(teacherID);
        console.log("id---------------", location);
        if (location.pathname === "/time") {
            setCourse_plan_id(location?.state?.id)
        } else {
        }

    }, []);
    //获取老师课表
    const getThaecherClass = async (id) => {
        // id =1有数据 teacherID
        const theacherRes = await theacherClass(id)

        const newDate = theacherRes.data.sort((s1, s2) => {
            if (s1.start_timestamp > s2.start_timestamp) {
                return -1;
            }
            return 1;
        });
        // console.log("--------------------", newDate);
        let array = [];
        let timeList = [];
        newDate.forEach((item) => {
            // console.log("item", item);
            let timeDate = item.start_timestamp;
            let arrObj = {};
            newDate.forEach((time) => {
                console.log("time", time);
                if (!timeList.includes(timeDate) && timeDate == time.start_timestamp) {
                    arrObj[`weekday_${time.weekday}`] = time;
                }
            });
            timeList.push(item.start_timestamp);
            array.push(arrObj);
        });

        // console.log('array', array);
        setTableData(array);
    }

    //学生约课
    const submitTiem = async () => {
        let data = localStorage.getItem("data");
        let planIDs = JSON.parse(data)
        console.log("planIDs======================", planIDs.toString());
        const timeData = {
            // teacher_id: datas.teacherId,
            // subject: datas.subject,
            // weekday: datas.weekday,
            // start_time: datas.startTime,
            // end_time: datas.endTime
            // plan_id: datas.ID,
            plan_ids: planIDs.toString(),
        }
        const res = await Appointment(timeData);
        if (res.code === 200) {
            message.success("约课成功");
        } else {
            message.error(res.msg);
        }
        localStorage.setItem("data", "")
        console.log(res);
    }
    //学生改时间
    // const changeTimeFun = async () => {
    //     let data1 = localStorage.getItem("data");
    //     let datas1 = JSON.parse(data1)

    //     //判断现在里上课的时间是多久  看修改的时候和现在距离多久
    //     console.log("datas1", datas1);
    //     const data = {
    //         course_plan_id: course_plan_id, //学生原课表ID
    //         plan_id: datas1.ID //老师课程计划ID
    //     }
    //     const res = await changeTime(data);
    //     if (res.code === 200) {
    //         message.success("修改成功")
    //     } else {
    //         message.error(res.msg)
    //     }

    //     console.log(res);
    // }

    return (
        <div className="teacher-homepage">
            <Table columns={columns} dataSource={tableData} bordered >

            </Table>
            <div className="submit">
                {
                    course_plan_id ? <button className="submit-btn" onClick={() => {
                        // changeTimeFun()
                    }}>提交修改</button> : <button className="submit-btn" onClick={() => {
                        setIsModalOpen(false);
                        submitTiem();
                    }}>提交预约</button>
                }


            </div>

        </div>
    );
};
export default Time;