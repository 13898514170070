import React from "react";
import { useState } from "react";
import { message } from "antd";
import "./style.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import Register from "../../components/register";
// import API from "../../api";
import { getVerification, loginApi } from '../../api'
import { SETTOKEN } from "../../utils/buizCont";
import { GETTOKEN } from "../../utils/buizCont";
const Login = () => {
  const [login, setLogin] = useState(true);
  const [passworld, setPassworld] = useState(true);
  const [verification, setVerification] = useState();
  const [loginData, setLoginData] = useState({

  });
  const nav = useNavigate();
  const getVerificationFun = async () => {
    //获取图形验证
    const resq = await getVerification();
    setVerification(resq.data);
    console.log('resq', resq);
  }
  useEffect(() => {
    if (GETTOKEN()) {
      console.log(GETTOKEN());
      nav("/teacherlist")
    } else {
      getVerificationFun();
    }
    // getVerificationFun();
  }, []);

  //是否出现登录注册
  const isLogin = () => {
    setLogin(!login);
  };
  const isPassworld = () => {
    console.log(1);
    setPassworld(!passworld);
  };
  //登录
  const handleLogin = async () => {
    console.log('----:loginDatad', loginData);
    const resq = await loginApi(loginData);
    console.log('res----------', resq);
    if (resq.code === 200) {
      //存储token
      SETTOKEN(resq.data.token);
      //登录成功跳转老师页面
      nav("/teacherlist");
    } else {
      message.error(resq.msg);
      getVerificationFun();
    }

    // console.log(res);
  };

  //忘记密码
  const RetrievePassword = () => { };
  return (
    <div className="login">
      <div className="login-content">
        <img src="../assets/login-bag.png" alt="" />
        {login ? (
          <div className="login-from ">
            <img src="../assets/logo.png" alt="" />
            {/* 登录 */}
            {passworld ? (
              <div>
                <div className="ipt-div">
                  <input
                    type="text"
                    placeholder="名字/Name"
                    className="login-name"
                    onBlur={(e) => {
                      setLoginData({ ...loginData, username: e.target.value });
                    }}
                  />
                </div>
                <p className="tips-text name-tips"></p>
                <div className="ipt-div">
                  <input
                    type="text"
                    placeholder="密码/Passwor"
                    className="login-pass"
                    onBlur={(e) => {
                      setLoginData({ ...loginData, password: e.target.value });
                    }}
                  />
                </div>
                <p className="tips-text name-tips"></p>
                <div className="ipt-div verification-div " >
                  <input
                    type="text"
                    placeholder="Enter verification code"
                    onBlur={(e) => {
                      setLoginData({
                        ...loginData, Captcha: e.target.value, captcha_id: verification.captchaId
                      });
                    }}
                  />
                  <img src={verification?.picPath} alt="" />
                </div>
                <p className="verification-p" onClick={() => { getVerificationFun() }}>换一张/Change one</p>

                <div className="login-btn" onClick={() => { handleLogin() }}> 登录/Sign in</div>
                <div className="switch-div">
                  <span
                    onClick={() => {
                      isLogin();
                    }}
                  >
                    注册/Register{" "}
                  </span>
                  <a
                    onClick={() => {
                      isPassworld();
                    }}
                  >

                  </a>
                </div>
              </div>
            ) : (
              <div>
                {/* 忘记密码 */}
                <div className="ipt-div">
                  <input
                    type="text"
                    placeholder="账号"
                    className="login-name"
                  />
                </div>

                <div className="ipt-div">
                  <input
                    type="text"
                    placeholder="手机号"
                    className="login-pass"
                  />
                </div>
                <div className="ipt-div">
                  <input
                    type="text"
                    placeholder="密码"
                    className="login-pass"
                  />
                </div>
                <div className="login-btn">提交</div>
                <div className="switch-div">
                  <a
                    onClick={() => {
                      isPassworld();
                    }}
                  >
                    登录
                  </a>
                </div>
              </div>
            )}
          </div>
        ) : (
          // 注册
          <Register setLogin={setLogin} />
        )}
      </div>
    </div>
  );
};
export default Login;
