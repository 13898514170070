import React, { useState } from "react";
import "./style.scss";
import Footer from "../../components/Footer";
import DPlayer from "dplayer";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Time from "../../components/Time";
import Headers from "../../components/Headers";
import { useEffect } from "react";
import { useLocation } from "react-router-dom"
import { theacherInfo, theacherClass, cheackTime, changeTime } from "../../api"

const TeacherInfo = () => {
    const Hls = require("hls.js");
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [theacherData, setTheacherData] = useState();
    const [teacherID, setTeacherID] = useState();
    const nav = useNavigate();

    useEffect(() => {
        setTeacherID(location?.state?.id);
        // console.log("id---------------", location.state.id);
        //拿到id
        theacherInfo(location.state.id).then((res) => {
            console.log(res);
            if (res.code === 200) {
                setTheacherData(res.data);
                const video = document.getElementById("video");
                const video1 = document.getElementById("video1")
                videoFun(video, res?.data?.course_demo);
                videoFun(video1, res?.data?.introduction_video);
                document.querySelector(".introduce-text").innerHTML = res?.data.introduction;
            }
        });
    }, []);
    //视频
    const videoFun = (dom, url) => {
        let video = new DPlayer({
            container: dom, // 注意：这里一定要写div的dom
            lang: "zh-cn",
            //customHls  https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4
            video: {
                url: url,
                type: "auto",
                pic: "",
                // customType: {
                //     customHls: function (video) {
                //         const hls = new Hls();
                //         hls.loadSource(video.src);
                //         hls.attachMedia(video);
                //     },
                // },
            },
        });
        video.play(); // 播放
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    //展示课表
    const getThaecherClass = async () => {
        setIsModalOpen(true);
    }
    //检测时间是否可约
    const CheackTheacherTime = async () => {
        const timeRes = await cheackTime(1, 1668916800, 1668917800);
        console.log(timeRes);
    }

    //学生改时间
    const changeTimeFun = async () => {
        const data = {
            course_plan_id: 1,
            teacher_id: 1,
            subject: "汉语",
            weekday: 1,
            start_time: 1668996000,
            end_time: 1668916800,
        }
        const res = await changeTime(data);
        console.log(res);
    }
    return (
        <div>
            <Modal
                title="课表"
                open={isModalOpen}
                onOk={handleOk}
                destroyOnClose={true}
                onCancel={handleCancel}
                width={"100%"}
                footer={null}
            >
                <Time setIsModalOpen={setIsModalOpen} teacherID={teacherID} />
            </Modal>

            <Headers />
            <img className="back-btn" src="../assets/back.png" alt="" onClick={() => { nav("/teacherlist") }} />
            <div className="teacher-info">

                <div className="user-info">
                    <div className="user-img">
                        < img src={theacherData?.header_img} alt="" />
                    </div>
                    <div className="user-content">
                        <p>姓名 :{theacherData?.nick_name}</p>
                        <p>语言：{theacherData?.language}</p>
                    </div>
                </div>
                <div className="introduce-video video" id="video"></div>
                <p className="introduce-p">自我介绍:</p >
                <div className="introduce-text">

                </div>
                <div className="class-video introduce-video video1" id="video1">
                </div>
                <div className="select-time">
                    <button
                        className="btn-time"
                        onClick={() => {

                            getThaecherClass()
                        }}
                    >
                        授课时间
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default TeacherInfo;