import { GETTOKEN } from "./buizCont";
// import { fetch } from 'whatwg-fetch'
import axios from 'axios'



const getFetchData = async (params, url) => {

  let res;
  let option = { method: params.method };
  if (params.method === "POST") {
    option.body = JSON.stringify(params.body);
  }
  option.headers = Object.assign({},
    { "Content-Type": "application/json" },
    { "API-TOKEN": `${GETTOKEN()} ` },
    params.headers,
  );

  // http://education.xibeicity.com/upload_files/course/20221019/l9fd8kvs_4vvn0dzxdqcj634fb3a39cfe9.png
  // http://papi.sino-bus.com
  // http://papi.pmo.club
  await axios({
    method: params.method,
    headers: option.headers,
    url: `http://papi.sino-bus.com${url}`,
    data: option.body,
  }).then((response) => {
    res = { ...response?.data }
    return response;
  }).catch((error) => {
    res = { ...error?.data }
  })
  return res


};

export default getFetchData;