import React from 'react';
import { Collapse } from 'antd';
import "./style.scss"
import Headers from '../../components/Headers';
import Footer from '../../components/Footer';
import { useEffect } from 'react';
import { leave, getTimetable, getClassRecord } from "../../api"
import { DatePicker, Space } from 'antd';
import { useState } from 'react';

const { Panel } = Collapse;
const text = (
  <p
    style={{
      paddingLeft: 24,
    }}
  >
    A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found
    as a welcome guest in many households across the world.
  </p>
);
const ClassRecord = () => {
  const [recordData, setRecordData] = useState([]);
  useEffect(() => {
    ClassRecordList();
  }, []);

  const onChange = (date, dateString) => {
    let datas = new Date(dateString).getTime();
    console.log(datas, dateString);
    ClassRecordList(datas);
  };
  const ClassRecordList = async (time) => {
    const res = await getClassRecord(time);
    console.log(res.data);
    setRecordData(res.data);
  }
  const columnsTime = (startTime, endTime) => {
    if (!startTime && !endTime) { return '' }
    const startDate = new Date(startTime);
    const startHours = startDate.getHours()
    const startMinutes = startDate.getMinutes();
    const endDate = new Date(endTime);
    const endHours = endDate.getHours()
    const endMinutes = endDate.getMinutes();
    return `${startHours}:${startMinutes < 10 ? '0' + startMinutes : startMinutes} - ${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`
  }
  const times = (num) => {
    var time = new Date(num).toLocaleString();
    return time
  }
  return (
    <div className="class-record">
      <Headers />
      <div className='content'>
        <p className='title'>可以根据时间去搜索</p>
        <Space direction="vertical" style={{
          width: '100%',
          margin: "10px"
        }}>
          <DatePicker
            onChange={onChange} style={{
              width: '50%',
              margin: "auto"
            }} />
        </Space>

        <Collapse bordered={false} defaultActiveKey={['1']}>
          {
            recordData?.map((item, index) => {
              return (
                <Panel header={`${times(item.start_time)}-${times(item.end_time)}`} key={index}>
                  <h2></h2>
                  <h2>上课内容：{item?.course_content}</h2>
                  <p>课堂表现：{item?.student_evaluate}</p>
                  <p>课后作业：{item?.homework}</p>
                </Panel>
              );
            })
          }
        </Collapse>
      </div>

      <div className='footer'>
        <Footer />
      </div>

    </div>
  );
};
export default ClassRecord;