import Header from "../../components/Headers"
import Footer from "../../components/Footer"
import "./style.scss"
import { Rate, message } from 'antd';
import { useState } from "react";
import { useNavigate } from "react-router-dom"
const Questionnaire = () => {
    const nav = useNavigate();
    const [list, setList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    const [answerList, setAnswerList] = useState([]);
    const [chName, setChName] = useState("");
    const [enName, setEnName] = useState("");
    const submitClic = () => {
        console.log(answerList);
        if (chName && enName) {
            if (answerList.length === list.length) {
                message.success("success")
                nav("/students")
            } else {
                message.error("There are unanswered questions")
            }
        } else {
            message.error("Please fill in your name");
        }
    }
    const onChangeFun = (v) => {
        setAnswerList([...answerList, v])

    }
    return (
        <div>
            <Header />
            {/* <iframe style={{
                width: "100%",
                height: "100%",
            }} src="https://www.wjx.cn/vm/wPikBcf.aspx" frameborder="0"></iframe> */}
            <div className="questionnaire">
                <h2>满意度评价</h2>
                <div> <span className="red">*</span>中文姓名：<input className="name-ipt" type="text" onChange={(e) => { setChName(e.target.value) }} /></div>
                <br />
                <span className="red">*</span>英文姓名：<input className="name-en-ipt" type="text" onChange={(e) => { setEnName(e.target.value) }} />
                <div className="questionnaire-content">
                    {
                        list.map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <p><span>*</span> {index + 1},您对于孩子课程难易程度是否满意？</p>
                                    <div className="yes">
                                        <div className="left">很不满意</div> <div className="right">很满意</div>

                                    </div>
                                    <Rate onChange={(v) => { onChangeFun(v) }} />
                                </div>
                            )
                        })
                    }

                </div>

            </div>
            <button className="submit-btn" onClick={() => { submitClic() }}>提交</button>
            <Footer />
        </div>
    )
}
export default Questionnaire