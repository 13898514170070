import getFetchData from "../utils/fetchRequest";

export const getVerification = async () => {
  // return await getFetchData({ method: "POST", body: data }, `/base/register`);

  return await getFetchData({ method: "GET" }, `/base/captcha`);

  // console.log('return', dayt);
}
//注册
export const register = async (data) => {
  return await getFetchData({ method: "POST", body: data }, `/base/register`);
}
//获取区号  https://study.gosinobus.com/site/get-area-code

//登录
export const loginApi = async (data) => {
  return await getFetchData({ method: "POST", body: data }, "/base/login");
}
//老师列表
export const theacherList = async (pages, size, keyword) => {
  return await getFetchData({ method: "GET", "Content-Type": "multipart/form-data", mode: 'cors' }, `/teacher/list?page=${pages}&size=${size}&keyword=${keyword}`);
}
//获取公告 http://127.0.0.1:10001/base/notice
export const getNotice = async () => {
  return await getFetchData({ method: "GET" }, "/base/notice");
}
//老师详情
export const theacherInfo = async (id) => {
  return await getFetchData({ method: "GET" }, `/teacher/info?teacher_id=${id}`)
}
//老师课表  http://127.0.0.1:10001/teacher/courses?teacher_id=1

export const theacherClass = async (id) => {
  return await getFetchData({ method: "GET" }, `/teacher/courses?teacher_id=${id}`)
}
//检测选定的时间是否可约  http://127.0.0.1:10001/teacher/check-teacher-free?teacher_id=1&begin_time=1668916800&end_time=1668917800
export const cheackTime = async (id, beginTime, endTime) => {
  return await getFetchData({ method: "GET" }, `/teacher/check-teacher-free?teacher_id=${id}&begin_time=${beginTime}&end_time=${endTime}`)
}
//学生约课  http://127.0.0.1:10001/student/book-course   post   Appointment
export const Appointment = async (data) => {
  return await getFetchData({ method: "POST", body: data }, `/student/book-course`)
}
//学生改时间  Change time   http://127.0.0.1:10001/student/edit-book-course
export const changeTime = async (data) => {
  return await getFetchData({ method: "POST", body: data }, `/student/edit-book-course`)
}
//学生请假 http://127.0.0.1:10001/student/vacate
export const leave = async (data) => {
  return await getFetchData({ method: "POST", body: data }, `/student/vacate`)
}

//用户详情
export const useinfo = async () => {
  return await getFetchData({ method: "GET" }, `/user/info`)
}
//获取学生课表  http://127.0.0.1:10001/student/course-plan
export const getTimetable = async () => {
  return await getFetchData({ method: "GET" }, `/student/course-plan`)
}
//获取学生上课记录 http://127.0.0.1:10001/student/course-log?date=2022-11-21  http://127.0.0.1:10001/student/course-log
export const getClassRecord = async (time) => {
  return await getFetchData({ method: "GET" }, `/student/course-log`)
}

//评价老师 http://127.0.0.1:10001/base/feedback
export const evaluateTheacher = async (data) => {
  return await getFetchData({ method: "POST", body: data }, `/student/evaluate-teacher`)
}