import { getVerification, register } from '../../api'
import "../../../src/pages/Login/style.scss"
import React, { useEffect, useState } from "react";
import { message } from 'antd';
const Register = ({ setLogin }) => {
    const [verification, setVerification] = useState();
    const [quData, setQuData] = useState([]);
    const [isPhone, setIsPhone] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isPass, setIspass] = useState(false);
    const [isAddes, setIsAddes] = useState(false);

    const [regData, setRegData] = useState({ authority_id: 333, real_name: "学生", language: "汉语" });
    const getVerificationfun = async () => {
        var date = new Date();
        date.getTimezoneOffset();
        console.log(date.getTimezoneOffset());
        //获取图形验证
        const resq = await getVerification();
        setVerification(resq.data);
        //获取区号  https://study.gosinobus.com/site/get-area-code

        fetch('https://study.gosinobus.com/index.php/site/get-area-code').then(
            //第一次先判断能否联系上服务器
            response => {
                console.log('联系服务器成功');
                return response.json();
            },
        ).then((res) => {
            console.log(res);
            setQuData(res.data)
        })
    }
    useEffect(() => {
        getVerificationfun();
    }, []);
    //name 验证
    const regNameReg = /^([\u4e00-\u9fa5]{2,20}|[a-zA-Z.\s]{2,20})$/;
    // 用户密码:必须包含一个大写，一个小写字母，且长度为8到16位。
    const passworldReg = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@&%#_]{8,16}$/;
    const phoneReg = /^([+]?0?\d{2,3}-?|\([+]?0?\d{2,3}\)|\([+]?0?\d{2,3}\))?\d+$|^([+]?0?\d{2,3}-?|\([+]?0?\d{2,3}\)|\([+]?0?\d{2,3}\))?[1-9]\d{4,10}(-\d{1,10})?$/;
    const nameCheck = (e) => {
        console.log(e.target.value, regNameReg.test(e.target.value));
        if (regNameReg.test(e.target.value)) {
            setRegData({ ...regData, username: e.target.value });
            document.querySelector(".name-tips").innerHTML = "";
            setIsName(true);
            console.log(isName);

        } else {
            document.querySelector(".name-tips").innerHTML = "格式不正确";
            setIsName(false);
        }

    }
    //  判断手机号是否正确 https://study.gosinobus.com/site/check-phone?phone=2403026131&regionCode=en
    const phoneQu = (phone, code) => {
        fetch(`https://study.gosinobus.com/index.php/site/check-phone?phone=${phone}&regionCode=${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(response => response.json()).then((res) => {
            console.log(res);
        })
    }
    //手机号验证
    const phoneCheck = (num) => {
        console.log(num, phoneReg.test(num));
        console.log(num);
        const qu = document.querySelector("#select").value;
        console.log(qu);
        var arr = qu.split("/");
        // console.log(arr);['日本', ' JP']
        if (phoneReg.test(num)) {
            if (qu) {
                //输入手机号 然后判断手机号和区号是否对应
                // const booler = phoneQu(arr[2] + num, arr[1]);
                fetch(`https://study.gosinobus.com/index.php/site/check-phone?phone=${arr[2] + num}&regionCode=${arr[1]}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(response => response.json()).then((res) => {
                    if (res.data.isValid) {
                        setRegData({ ...regData, mobile: num, area: arr[0], });
                        document.querySelector(".phone-tips").innerHTML = "";
                        setIsPhone(true);
                    } else {
                        document.querySelector(".phone-tips").innerHTML = "手机号与区号不符";
                        setIsPhone(false);
                    }

                })

            } else {
                document.querySelector(".phone-tips").innerHTML = "请选择正确的区号";
                setIsPhone(false);
            }

        } else {
            document.querySelector(".phone-tips").innerHTML = "格式不正确";
            setIsPhone(false);
        }

    }
    //密码
    const passCheck = (e) => {
        console.log(e.target.value, passworldReg.test(e.target.value));
        if (passworldReg.test(e.target.value)) {
            setRegData({ ...regData, password: e.target.value, retry_password: e.target.value });
            document.querySelector(".pass-tips").innerHTML = "";
            setIspass(true)
        } else {
            document.querySelector(".pass-tips").innerHTML = "包含一个大写，一个小写字母，且长度为8到16位";
            setIspass(false);
        }

    }
    //地址
    const addesCheck = (e) => {
        console.log(e.target.value);
        if (e.target.value) {
            setRegData({ ...regData, address: e.target.value });
            document.querySelector(".address-tips").innerHTML = "";
            setIsAddes(true);
        } else {
            setIsAddes(false);
            document.querySelector(".address-tips").innerHTML = "地址未填写";
        }

    }

    //注册
    // username  mobile password retry_password address real_name authority_id authority_id area language Captcha CaptchaId
    const handleRegister = async () => {
        console.log(isName, isPhone, isAddes, isPass);
        if (isName && isPhone && isAddes && isPass) {
            const res = await register(regData);
            console.log(res);

            if (res.code == 200) {
                //跳登录
                setLogin(true)
                message.success("注册成功");

            } else {
                message.error(res.msg);
                const resq = await getVerification();
                setVerification(resq.data);
            }
            console.log("regData", regData);
        } else {
            message.error("上面信息未填写完整");
        }

    };
    return (
        <div className="register login-from">
            <img src="../assets/logo.png" alt="" />
            <div className="ipt-div">
                <input type="text" placeholder="名字/Name" className="reg-name" onBlur={(e) => {
                    nameCheck(e)

                }} />
            </div>
            <p className="tips-text name-tips"></p>
            <div className="ipt-div ipt-phone">
                <select name="" id="select" onChange={(event) => { phoneCheck(document.querySelector(".reg-phone").value) }}>
                    {
                        quData?.map((item, index) => {
                            return (
                                <option value={`${item.chinese_name}/${item.code}/${item.num}`} key={index}>{item.num} /{item.chinese_name}</option>
                            )
                        })
                    }
                </select>
                <input type="text" placeholder="手机号/Phone" className="reg-phone" onBlur={(e) => {
                    phoneCheck(e.target.value);
                }} />
            </div>
            <p className="tips-text phone-tips" ></p>
            <div className="ipt-div">
                <input
                    type="text"
                    placeholder="地址/Address"
                    className="reg-address"
                    onBlur={(e) => {
                        addesCheck(e);

                    }}
                />
            </div>
            <p className="tips-text address-tips"></p>
            <div className="ipt-div">
                <input
                    type="text"
                    placeholder="密码/Password"
                    className="reg-pass"
                    onBlur={(e) => {
                        passCheck(e)

                    }}
                />
            </div>
            <p className="tips-text pass-tips"></p>
            <div className="ipt-div verification-div " >
                <input
                    type="text"
                    placeholder="Enter verification code"
                    onBlur={(e) => {
                        setRegData({
                            ...regData, Captcha: e.target.value, captcha_id: verification.captchaId
                        });
                    }}
                />
                <img src={verification?.picPath} alt="" />
            </div>
            <p className="verification-p" onClick={() => { getVerificationfun() }}>换一张/Change one</p>
            <div className="login-btn" onClick={() => { handleRegister() }}>注册/Register</div>
            <div className="switch-div">
                <span
                    onClick={() => {
                        setLogin(true)
                    }}
                >
                    已有账号去登录/Login with an existing account
                </span>
            </div>
        </div>
    )
}
export default Register;