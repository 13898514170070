// export const buizCont = () => {
//     API: 'http://study.gosinobus.com/'
// }

export const SETTOKEN = (token) => {
  sessionStorage.setItem("token", token);
};

export const GETTOKEN = () => {
  return sessionStorage.getItem("token");
};
export const REMTOKEN = () => {
  sessionStorage.removeItem("token");
};

export function jsonToFormData(config) {
  const formData = new FormData();
  //循环传入的值转换formData
  Object.keys(config).forEach((key) => {
    formData.append(key, config[key]);
  })
  return formData;
}
