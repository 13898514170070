import React, { useEffect } from "react";
import Headers from "../../components/Headers";
import Footer from "../../components/Footer";
import "./style.scss"
import { useinfo } from '../../api'
import { useState } from "react";
import { evaluateTheacher, getTimetable, getClassRecord, changeTime } from "../../api"
import { useNavigate } from "react-router-dom";
import CalendarFun from './calendar';
import NewCalendar from './newCalendar';
import configData from "./config";
import { HeartOutlined } from '@ant-design/icons';
import { Rate, message, Modal, Input } from 'antd';
import context from "../../context";
const UserInfo = () => {
    const [userData, setUserData] = useState();
    const [studentTimeTable, setStudentTimeTable] = useState(); //学生课表
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [text, setText] = useState();
    const [nums, setNums] = useState(false);
    const nav = useNavigate()
    const { TextArea } = Input;

    useEffect(() => {
        UserInfoData()
        studentClassTable();
        // ClassRecordList();
    }, []);
    //获取用户信息
    const UserInfoData = async () => {
        // const resq = await theacherList(1, 10);
        const resq = await useinfo();
        if (resq.code === 200) {
            setUserData(resq.data);
        } else {
            //暂无信息
        }
        console.log('resq', resq);
    }
    //获取学生课表
    const studentClassTable = async () => {
        const res = await getTimetable();
        setStudentTimeTable(res)
    }
    //学生上课记录
    const ClassRecordList = async () => {
        const res = await getClassRecord("2022-11-21");
        console.log(res);
    }
    const onChangeFun = async (num) => {
        setNums(num);


    }
    const hanldOk = async () => {
        if (studentTimeTable?.data[0]) {
            const thData = {
                teacher_id: studentTimeTable?.data[0]?.teacher_id,
                score: nums,
                context: text
            }
            const res = await evaluateTheacher(thData);
            if (res.code === 200) {
                message.success("已评价");
                setIsModalOpen(false)
            } else {
                message.error("未到评价时间哦");
            }
        } else {
            message.error("还未选择老师约课哦");
        }
    }
    return (<div className="user-info">
        <Headers />
        <img className="back-btn" src="../assets/back.png" alt="" onClick={() => { nav("/teacherlist") }} />
        <div className="user-box">
            <div className="user-content">
                <img src={userData?.header_img} alt="" className="user-img" />
                <div className="user-details">
                    <p>姓 名 ：{userData?.nick_name}</p>
                    <p>电 话：{userData?.phone}</p>
                    <p className="reacrd-text" onClick={() => {
                        setIsModalOpen(!isModalOpen)
                    }}>给我的老师评价</p>
                    {/*  */}
                    <Modal title={"送给老师小心心"}
                        open={isModalOpen}
                        onOk={hanldOk}
                        destroyOnClose={true}
                        onCancel={() => { setIsModalOpen(false) }}
                        okText={"Submit"}>
                        <div> 您对老师近期工作的总体评价： <span>{nums * 20}</span>分</div>
                        <Rate character={<HeartOutlined />} allowHalf onChange={(v) => { onChangeFun(v) }} />
                        <TextArea rows={4} placeholder="意见栏" maxLength={600} showCount={true} onChange={(event) => {
                            console.log(event.target.value);
                            setText(event.target.value)
                        }} />
                    </Modal>
                    <p className="reacrd-text" onClick={() => {
                        nav("/class-record");
                    }}>上 课 记 录</p>
                </div>
            </div>
        </div>
        {/* 课表 */}
        <div className="classTable">
            {/* <CalendarFun configData={configData} /> */}
            {
                studentTimeTable ? <NewCalendar configData={configData} studentTimeTable={studentTimeTable}></NewCalendar> : null
            }

        </div>

        <Footer />

    </div>);

}
export default UserInfo;