import React from "react";
import "./style.scss";
import { getNotice } from '../../api'
import { useEffect } from "react";
import { Carousel } from 'antd';
import { useState } from "react";
const Footer = () => {
  const [data, setData] = useState();
  useEffect(() => {
    noticeList();

  }, []);
  let a = [1, 2, 3]
  //获取公告
  const noticeList = async () => {
    const res = await getNotice();
    console.log(res.data);
    setData(res.data)
  }
  const contentStyle = {
    margin: 0,
    height: '60px',
    color: '#fff',
    lineHeight: '60px',
    textAlign: 'center',
    background: '#364d79',
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <div className="footer">
      <img src="../assets/footer.png" alt="" className="footer-img-pc" />
      <img src="../assets/footer-wap.png" alt="" className="footer-img-wap" />
      <div className="swiper">

        <Carousel afterChange={onChange} autoplay>
          {
            data?.map((item, index) => {
              console.log(item);
              return (<div key={index}>
                <h2 >{item.title}</h2>
                <p>{item.content}</p>
              </div>)

            })
          }

        </Carousel>
      </div>

      {/* <Carousel autoplay></Carousel> */}
    </div>

  );
};
export default Footer;
